export const MAX_IMPORT_SIZE = 1024000 // 1GB
export const MAX_AVATAR_SIZE = 1024 // 1MB

export enum ACCESS_LOG_TYPE {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  LIST = 'LIST',
}

export enum ImportType {
  CSV = 'CSV',
  PDF = 'PDF',
  DOCX = 'DOCX',
}
