import { RiRefreshLine } from '@remixicon/react'
import { Button } from 'antd'

import Maintenance from '@/assets/Maintenance'

interface UnderMaintenanceProps {
  onRefreshClick?: () => void
}

const UnderMaintenance: React.FC<UnderMaintenanceProps> = ({
  onRefreshClick,
}) => {
  return (
    <div className='flex min-h-screen w-full flex-col items-center justify-center gap-6 bg-surface p-3 text-on-surface'>
      <Maintenance />
      <div className='flex flex-col items-center gap-2 text-center'>
        <div className='text-2xl'>Oh no, something went wrong!</div>
        <div className='flex flex-col text-center'>
          <span className='text-lg'>
            Our site is under maintenance right now.
          </span>
          <span className='text-lg'>
            We're doing our best and we'll be back soon.
          </span>
        </div>
      </div>
      {onRefreshClick && (
        <Button
          className='flex w-fit items-center px-3 text-on-surface'
          onClick={onRefreshClick}
          icon={<RiRefreshLine className='size-5' />}
        >
          Refresh
        </Button>
      )}
    </div>
  )
}

export default UnderMaintenance
