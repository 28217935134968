import { createContext } from 'react'

import { TourProps } from './tour.types'

export const TourContext = createContext<TourProps>({
  tourRef1: { current: null },
  tourRef2: { current: null },
  tourRef3: { current: null },
  tourSteps: [],
  openTour: false,
  startTour: () => undefined,
})
