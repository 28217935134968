/*
 * https://www.tints.dev/
 * https://m2.material.io/design/color/the-color-system.html#color-theme-creation
 */

import { Metadata } from 'next'

import { COMPANY } from './constants/env'

export const mode: 'light' | 'dark' = 'dark'

export const colors = {
  primary: '#ffffff',
  'primary-light': '#ffffff',
  'primary-dark': '#8C8C8C', // Not used anywhere yet but part of material design
  secondary: '#ffffff', // primary buttons
  surface: '#0e0e11',
  background: '#262626',
  error: '#b00020', // Not used anywhere yet but part of material design
  on: {
    primary: '#0D0D0D',
    secondary: '#0D0D0D',
    surface: '#ffffff',
    background: '#f2f2f2',
    error: '#f2f2f2', // Not used anywhere yet but part of material design
  },
  gray: {
    50: '#E6E6E6',
    100: '#D9D9D9',
    200: '#BFBFBF',
    300: '#A6A6A6',
    400: '#8C8C8C',
    500: '#737373',
    600: '#595959',
    700: '#404040',
    800: '#262626',
    900: '#0D0D0D',
    950: '#000000',
  },
}

export const sidebarTitle: string = 'AI'

// DEVELOPMENT: height: 80, top: 'top-[6.5em]'
// PRODUCTION: height: 60, top: 'top-28'
export const logoLoginClx = 'h-[80px] w-auto top-[6.5em]'
export const logoIndexClx = 'h-[42px] w-auto'
export const logoSidebarClx = 'h-[32px] w-auto'

export const poweredBy = {
  text: 'SE7EN',
  url: 'https://www.se7en.si/',
}

export const showIndexHeaderText = true

export const brandMetadata: Metadata = {
  title: `${COMPANY ?? ''} AI Chat bot`,
  description: 'Get answers to domain questions.',
  icons: [{ rel: 'icon', url: '/favicon.ico' }],
}
